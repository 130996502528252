<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="msaBlue white--text"> Settings </v-card-title>
      <v-card-text class="pa-4">
        <v-row :style="{ 'border-bottom': '1px black dashed' }">
          <v-col> GENERAL </v-col>
        </v-row>

        <v-row
          v-for="(setting, settingKey) in generalSettings"
          v-show="isOptionShowing(settingKey)"
          :key="setting.displayName"
          align="center"
        >
          <v-col class="grow ml-4">
            {{ setting.displayName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ setting.toolTip }}
            </v-tooltip>
          </v-col>
          <v-col
            v-if="settingKey == 'emailNotificationStart'"
            class="shrink"
            style="min-width: 300px"
          >
            <v-menu
              v-model="setting.isMenuOpen"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="setting.time"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details
                  outlined
                  readonly
                />
              </template>
              <v-time-picker
                v-model="setting.time"
                @click:minute="updateCompanySetting(setting, settingKey)"
                format="24hr"
              />
            </v-menu>
          </v-col>

          <v-col
            v-else-if="settingKey == 'timeZoneId'"
            class="shrink"
            style="min-width: 300px"
          >
            <v-select
              v-model="setting.timeZoneId"
              :items="timeZones"
              @change="updateCompanySetting(setting, settingKey)"
              dense
              hide-details
              item-text="timeZoneDisplay"
              item-value="id"
              outlined
            />
          </v-col>

          <v-col v-else class="shrink">
            <v-switch
              v-model="setting.isEnabled"
              :false-value="0"
              :true-value="1"
              @change="updateCompanySetting(setting, settingKey)"
              class="mt-0"
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-row :style="{ 'border-bottom': '1px black dashed' }">
          <v-col> PROJECT </v-col>
        </v-row>

        <v-row
          v-for="(setting, settingKey) in projectSettings"
          :key="setting.displayName"
          align="center"
        >
          <v-col class="grow ml-4">
            {{ setting.displayName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ setting.toolTip }}
            </v-tooltip>
          </v-col>
          <v-col class="shrink">
            <v-switch
              v-model="setting.isEnabled"
              :false-value="0"
              :true-value="1"
              @change="updateCompanySetting(setting, settingKey)"
              class="mt-0"
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'CompanySettingsPage',
  data() {
    return {
      refreshTimeMenuKey: false,
      settings: {},
      generalSettings: {
        employeeProfileEditingEnabled: {
          isEnabled: 0,
          displayName: 'User Profile Editing',
          toolTip: 'If enabled, users will be allowed to edit their profiles.',
        },
        isEmployeePrivacyEnabled: {
          isEnabled: 0,
          displayName: 'User Privacy',
          toolTip:
            'If enabled, users will not be able to see other users personal information.',
        },
        isUserNotificationEnabled: {
          isEnabled: 0,
          displayName: 'Notifications',
          toolTip: 'Enable or disable user notifications for your company.',
        },
        emailNotificationStart: {
          time: 0,
          isMenuOpen: false,
          displayName:
            'Schedule the time of Email Notification to be sent after',
          toolTip: 'Enable or disable user notifications for your company.',
        },
        timeZoneId: {
          displayName: 'Time Zone for Form Exporter ',
          toolTip:
            'Automatically adjusts between standard and daylight time for time zones with two zones listed (e.g., MST/MDT).',
        },
        allowMobileUserToCreateTags: {
          isEnabled: 0,
          displayName: 'Allow users to create tags',
          toolTip: 'If enabled, users will be able to create form tags.',
        },
        showArchivedTagsForFormFilter: {
          isEnabled: 0,
          displayName: 'Show archived form tags',
          toolTip:
            'If enabled, users will be able to see archived form tags when filtering forms.',
        },
        isFormFinalizationWithUnresolvedCAEnabled: {
          isEnabled: 0,
          displayName:
            'Forms with unresolved corrective actions cannot be finalized',
          toolTip:
            'If enabled, users will not be able to finalize forms with unresolved corrective actions.',
        },
      },
      projectSettings: {
        filterFormListsByProject: {
          isEnabled: 0,
          displayName: 'Project Filters',
          toolTip:
            'If enabled, users will only see project(s) the user is assigned to and only be able to assign the form to a user in the selected project(s).',
        },
        filterEmployees: {
          isEnabled: 0,
          displayName: 'User Filters',
          toolTip:
            'If enabled, users will only be able to see users assigned to the project when signing off on a form or document.',
        },
        filterTrainingRecords: {
          isEnabled: 0,
          displayName: 'Training Records Filters',
          toolTip:
            'If enabled, supervisor(s) will see only training records from users assigned to their project(s).',
        },
        filterFilledOutForms: {
          isEnabled: 0,
          displayName: 'Forms Filters',
          toolTip:
            'If enabled, supervisor(s) will see all forms from users assigned to their project(s).',
        },
        filterDocumentSignOffs: {
          isEnabled: 0,
          displayName: 'Signed Documents Filters',
          toolTip:
            'If enabled, supervisor(s) will see all signed documents from users assigned to their project(s).',
        },
      },
      timeZones: [],
    };
  },
  methods: {
    updateCompanySetting(setting, settingKey) {
      const isSettingTime = 'time' in setting;

      let newValue = '';
      if (settingKey == 'timeZoneId') {
        newValue = setting.timeZoneId;
      } else {
        newValue = isSettingTime ? setting.time : setting.isEnabled;
      }

      if (isSettingTime) {
        this.refreshTimeMenuKey = !this.refreshTimeMenuKey;
      }

      const params = {
        loaderText: 'Saving...',
        setting: settingKey,
        snackText: 'Company setting updated.',
        value: newValue,
      };
      const url = 'update-company-setting?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          if (settingKey == 'allowMobileUserToCreateTags') {
            const session = JSON.parse(
              JSON.stringify(this.$store.getters.user),
            );
            session.createTagsOnTheFly =
              this.generalSettings.allowMobileUserToCreateTags.isEnabled;
            this.$store.commit('updateUser', session);
          }
        })
        .catch((error) => error);
    },
    getCompanySettings() {
      const url = 'get-company-settings?format=json';
      const params = {
        loaderText: 'Loading...',
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          this.settings = response.data;

          this.getTimeZones();

          this.buildGeneralSettings();
          this.buildProjectSettings();
        })
        .catch((error) => error);
    },
    getTimeZones() {
      this.$axios
        .post('get-time-zones?format=json', { loaderText: 'Loading...' })
        .then((response) => {
          this.timeZones = response.data;
        });
    },
    buildGeneralSettings() {
      for (const setting in this.generalSettings) {
        switch (setting) {
          case 'emailNotificationStart':
            this.generalSettings[setting].time =
              this.$options.filters.filterAsLocalTime(this.settings[setting]);
            break;
          case 'timeZoneId':
            this.generalSettings[setting].timeZoneId = this.settings[setting];
            break;
          default:
            this.generalSettings[setting].isEnabled = this.settings[setting];
            break;
        }
      }
    },
    buildProjectSettings() {
      for (const setting in this.projectSettings) {
        this.projectSettings[setting].isEnabled = this.settings[setting];
      }
    },
    isOptionShowing(settingKey) {
      if (settingKey != 'emailNotificationStart') {
        return true;
      }

      if (this.generalSettings['isUserNotificationEnabled'].isEnabled) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.getCompanySettings();
  },
};
</script>
